
import 'mdn-polyfills/NodeList.prototype.forEach';

export default class Contact {

  constructor(element) {
    this.element = element;

    this.subjectField = element.querySelector('#subject');
    this.messageField = element.querySelector('#message');
    this.submitButton = element.querySelector('button[type="submit"]');
    this.applicationError = element.querySelector('.js-pardot-form-application');

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    if (String(this.subjectField.value).toLowerCase().indexOf('candidature') >= 0) {
      this.messageField.setAttribute('disabled', '');
      this.submitButton.setAttribute('disabled', '');
      this.applicationError.classList.remove('u-hidden');
    }
    else {
      this.messageField.removeAttribute('disabled');
      this.submitButton.removeAttribute('disabled');
      this.applicationError.classList.add('u-hidden');
    }
  }

  mount() {
    this.subjectField.addEventListener('change', this.handleChange);
  }

  unmount() {
  }
}
