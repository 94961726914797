import 'mdn-polyfills/NodeList.prototype.forEach';
import 'promise-polyfill/src/polyfill';
import 'whatwg-fetch';
import 'url-search-params-polyfill';
import { debounce } from '../../utilities/utilities';

export default class Search {

  constructor(element) {
    this.element = element;
    this.form = this.element.querySelector('[data-search-form]');
    this.input = this.element.querySelector('[data-search-input]');
    this.results = this.element.querySelector('[data-search-results]');
    this.theme = this.form.dataset.searchTheme;

    this.handleDebouncedKeyUp = debounce(this.handleDebouncedKeyUp.bind(this), 300);
  }

  handleDebouncedKeyUp(e) {
    // const formData = new FormData(this.form);
    // formData.append('format', 'json');
    // formData.append('theme', this.theme);
    // const params = new URLSearchParams(formData).toString();

    const params = `${this.input.name}=${this.input.value}&format=json&theme=${this.theme}`;

    const init = {
      method: this.form.method,
      headers: {
        'Content-Type': 'application/json'
      },
      cache: 'default',
    };

    if (this.input.value !== "") {
      window.fetch(`${this.form.action}?${params}`, init)
        .then((response) => {
          if (!response.ok) {
            throw Error(response.statusText);
          }

          return response.json();
        })
        .then((json) => {
          this.showResults(json);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      this.results.innerHTML = '';
    }
  }

  generateResultsLine(data) {
    let html = `<li class="c-search__item">`;
    html += `<a class="c-search__link" href="${data.link}">${data.post_title}`;

    if (Object.prototype.hasOwnProperty.call(data, 'theme')) {
      html += ` <span class="c-tag c-search__tag u-background-${data.theme.id} u-color-foreground-light">${data.theme.name}</span>`;
    }

    html += `</a>`;
    html += `</li>`;
    return html;
  }

  generateResults(data, id) {
    let html = `<div class="c-search__block">`;
    html += `<p class="c-search__label">${data.name} (${data.total})</p>`;
    html += `<ul class="c-search__items">`;

    data.results.forEach((result) => {
      html += this.generateResultsLine(result);
    });

    html += `</ul>`;
    html += `<p><a class="c-search__more" href="${data.link}">Tous les résultats${ id !== 'others' ? ` ${data.name}` : ''}</a></p>`;
    html += `</div>`;
    return html;
  }

  showResults(json) {
    let atLeastOneResult = false;
    let html = ``;

    Object.keys(json).forEach((key) => {
      if (json[key].results.length > 0) {
        atLeastOneResult = true;
        html += this.generateResults(json[key], key);
      }
    });

    if (!atLeastOneResult) {
      html = `<div class="c-search__block s-richtext"><p>Aucun résultat pour cette recherche.</p></div>`;
    }

    this.results.innerHTML = html;
  }

  mount() {
    this.input.addEventListener('keyup', this.handleDebouncedKeyUp);
  }

  unmount() {
    this.input.removeEventListener('keyup', this.handleDebouncedKeyUp);
  }
}
