import 'mdn-polyfills/NodeList.prototype.forEach';
import Iframe from './Iframe';

document.addEventListener('DOMContentLoaded', () => {
  const iframes = document.querySelectorAll('[data-iframe]');
    if (!iframes.length) {
      return;
    }
    iframes.forEach((element) => {
      const iframe = new Iframe(element);
      iframe.mount();
    });
});
