import marquee from 'vanilla-marquee'

/**
 * Name: Marquee
 * Description: Create Marquee behavior with vanilla-marquee library
 */

export default class Marquee {
  constructor (element) {
    this.element = element;
    this.scroller = element.querySelector('[data-marquee-scroller]');

    this.handleIntersect = this.handleIntersect.bind(this);
  }

  handleIntersect (entries, observer) {
    entries.forEach((entry) => {
      if (entry.intersectionRatio > 0 && this.marquee._status == "paused") {
        this.marquee.resume();
      } else if (entry.intersectionRatio === 0 && this.marquee._status == "running") {
        this.marquee.pause();
      }
    });
  }

  mount () {
    this.marquee = new marquee(this.scroller, {
      duplicated: true,
      speed: 150,
      gap: 0,
      delayBeforeStart: 50,
      startVisible: true,
      pauseOnHover: true,
    });

    this.marquee.pause();

    this.marquee.el.addEventListener('paused', this.handlePaused);

    this.observer = new IntersectionObserver(this.handleIntersect, {
      rootMargin: '0px',
      threshold: [0, 1],
    });

    this.observer.observe(this.scroller);

    this.element.classList.add('is-mounted');
  }

  unmount () {
    this.marquee.destroy();
    this.marquee = null;
    this.observer.unobserve(this.scroller);
    this.element.classList.remove('is-mounted');
  }
}
