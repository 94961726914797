import 'mdn-polyfills/NodeList.prototype.forEach';
import { debounce } from '../../utilities/utilities';
import Toggler from '../toggler/Toggler';
import Navigation from '../navigation/Navigation';
import Search from '../search/Search';

export default class Header {

  constructor(element) {
    this.element = element;
    this.top = this.element.querySelector('#header-top');
    this.main = this.element.querySelector('#header-main');
    this.navigationTogglers = [];
    this.searchTogglers = [];
    this.tmp = 0;
    this.navigation = null
    this.navigationToggler = null;
    this.search = null;
    this.searchToggler = null;
    this.hasOverlay = false;
    this.countOverlay = 0;

    this.handleSearchHide = this.handleSearchHide.bind(this);
    this.handleSearchShow = this.handleSearchShow.bind(this);
    this.handleNavigationHide = this.handleNavigationHide.bind(this);
    this.handleNavigationShow = this.handleNavigationShow.bind(this);
    this.handleNavigationUpdate = this.handleNavigationUpdate.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleDebouncedResize = debounce(this.handleDebouncedResize.bind(this), 100);
  }

  handleNavigationHide() {
    this.navigation.togglers.forEach((toggler) => {
      toggler.hide(true);
    });

    this.navigationToggler.focus();
    this.navigationToggler = null;
    this.update();
  }

  handleNavigationShow(e) {
    this.navigationToggler = e.detail.toggler.element;
    this.update();
  }

  handleNavigationUpdate() {
    this.update();
  }

  handleSearchHide(e) {
    this.searchToggler.focus();
    this.searchToggler = null;
    this.update();
  }

  handleSearchShow(e) {
    this.searchToggler = e.detail.toggler.element;
    this.search.input.focus();
    this.update();
  }

  handleScroll() {
    document.documentElement.style.setProperty('--header-effective-height', `${this.element.offsetHeight + this.element.getBoundingClientRect().y }px`);
  }

  handleDebouncedResize() {
    if (this.tmp !== this.element.offsetWidth) {

      if (this.top) {
        document.documentElement.style.setProperty('--header-top-height', `${this.top.offsetHeight}px`);
      }
      document.documentElement.style.setProperty('--header-main-height', `${this.main.offsetHeight}px`);
      document.documentElement.style.setProperty('--header-height', `${this.element.offsetHeight}px`);

      this.navigationTogglers.forEach((toggler) => {
        const isTogglable = window.getComputedStyle(toggler.element).getPropertyValue('display') !== 'none';

        if (toggler.mounted !== isTogglable) {
          if (isTogglable) {
            toggler.mount();
          } else {
            toggler.unmount();
          }
        }
      });

      this.tmp = this.element.offsetWidth;
    }
  }

  update() {
    const isSearchHidden = this.search.element.getAttribute('aria-hidden') === 'true';
    let isNavigationHasTogglerExpanded = false;

    this.navigation.togglers.forEach((toggler) => {
      if (!toggler.target.contains(toggler.element)) {
        isNavigationHasTogglerExpanded = toggler.expanded === true ? true : isNavigationHasTogglerExpanded;
      }
    });

    if ((!isSearchHidden || isNavigationHasTogglerExpanded ) && !this.hasOverlay) {
      document.body.classList.add('has-overlay');
      this.hasOverlay = true;
    } else if ((isSearchHidden && !isNavigationHasTogglerExpanded ) && this.hasOverlay) {
      document.body.classList.remove('has-overlay');
      this.hasOverlay = false;
    }
  }

  mount() {
    this.element.querySelectorAll('[data-header-toggler="navigation"]').forEach((element) => {
      const target = document.getElementById(element.dataset.headerToggler);
      const toggler = new Toggler(element, target);
      this.navigationTogglers.push(toggler);
      element.addEventListener('toggler:hide', this.handleNavigationHide);
      element.addEventListener('toggler:show', this.handleNavigationShow);

      if (window.getComputedStyle(element).getPropertyValue('display') !== 'none') {
        toggler.mount();
      }
    });

    const nav = this.element.querySelector('#navigation');
    if (nav) {
      this.navigation = new Navigation(nav);
      this.navigation.element.addEventListener('navigation:update', this.handleNavigationUpdate);
      this.navigation.mount();
    }

    const search = this.element.querySelector('#search');
    if (search) {
      this.search = new Search(search);
      this.element.querySelectorAll('[data-header-toggler="search"]').forEach((element) => {
        const target = document.getElementById(element.dataset.headerToggler);
        const toggler = new Toggler(element, target, true);
        this.searchTogglers.push(toggler);
        toggler.mount();

        element.addEventListener('toggler:hide', this.handleSearchHide);
        element.addEventListener('toggler:show', this.handleSearchShow);
      });
      this.search.mount();
    }

    window.addEventListener('resize', this.handleDebouncedResize);
    window.addEventListener('scroll', this.handleScroll);
    this.handleDebouncedResize();
    this.handleScroll();
  }

  unmount() {
    window.removeEventListener('resize', this.handleDebouncedResize);
    window.removeEventListener('scroll', this.handleScroll);

    this.navigation.unmount();
    this.navigation.element.removeEventListener('navigation:update', this.handleNavigationUpdate);
    this.navigationTogglers.forEach((toggler) => {
      toggler.element.removeEventListener('toggler:hide', this.handleNavigationHide);
      toggler.element.removeEventListener('toggler:show', this.handleNavigationShow);
      toggler.unmount();
    });

    this.search.unmount();
    this.searchTogglers.forEach((toggler) => {
      toggler.element.removeEventListener('toggler:hide', this.handleSearchHide);
      toggler.element.removeEventListener('toggler:show', this.handleSearchShow);
      toggler.unmount();
    });
  }
}
