import 'mdn-polyfills/NodeList.prototype.forEach';
import Marquee from './Marquee';

document.addEventListener('DOMContentLoaded', () => {
  const marquees = document.querySelectorAll('[data-marquee]');
  if (!marquees.length) {
    return;
  }
  marquees.forEach((element) => {
    const marquee = new Marquee(element);
    marquee.mount();
  });
});
