import 'mdn-polyfills/NodeList.prototype.forEach';
import Toggler from '../toggler/Toggler';

export default class Navigation {

  constructor(element) {
    this.element = element;
    this.togglers = [];
    this.toggler = null;
    this.tmp = this.element.offsetWidth;

    this.handleTogglerHide = this.handleTogglerHide.bind(this);
    this.handleTogglerShow = this.handleTogglerShow.bind(this);
  }

  handleTogglerHide(e) {
    this.toggler.focus();
    if (this.toggler === e.detail.toggler.element) {
      this.toggler = null;
    }
    this.element.dispatchEvent(new CustomEvent("navigation:update"));
  }

  handleTogglerShow(e) {
    this.togglers.forEach((toggler) => {
      if (toggler.target !== e.detail.toggler.target && toggler.expanded) {
        toggler.hide();
      }
    });
    this.toggler = e.detail.toggler.element;
    this.element.dispatchEvent(new CustomEvent("navigation:update"));
  }

  mount() {
    this.element.querySelectorAll('[data-navigation-toggler]').forEach((element) => {
      const target = document.getElementById(element.dataset.navigationToggler);
      const toggler = new Toggler(element, target, true);
      this.togglers.push(toggler);

      element.addEventListener('toggler:hide', this.handleTogglerHide);
      element.addEventListener('toggler:show', this.handleTogglerShow);

      toggler.mount();
    });
  }

  unmount() {
    this.togglers.forEach((toggler) => {
      toggler.element.removeEventListener('toggler:hide', this.handleTogglerHide);
      toggler.element.removeEventListener('toggler:show', this.handleTogglerShow);
      toggler.unmount();
    });

    this.togglers = [];
  }
}
