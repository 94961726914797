import 'mdn-polyfills/NodeList.prototype.forEach';

/**
 * Accordion Element based on WAI-ARIA Practices
 * https://www.w3.org/TR/wai-aria-practices-1.1/examples/accordion/accordion.html
 */

export default class Accordion {
  constructor(element) {
    this.element = element;
    this.controls = this.element.querySelectorAll('[data-accordion-control]');
    this.current = null;

    this.handleSummaryClick = this.handleSummaryClick.bind(this);
    this.handleSummaryKeydown = this.handleSummaryKeydown.bind(this);
  }

  /**
   * @param {HTMLElement} control
   */
  deactivate(control) {
    const id = control.dataset.accordionControl;
    const target = document.getElementById(id);
    control.setAttribute('aria-expanded', 'false');
    control.removeAttribute('aria-disabled');
    target.setAttribute('hidden', '');
    this.current = null;
  }

  /**
   * @param {HTMLElement} control
   */
  activate(control) {
    const id = control.dataset.accordionControl;
    const target = document.getElementById(id);
    control.setAttribute('aria-expanded', 'true');
    control.setAttribute('aria-disabled', '');
    target.removeAttribute('hidden');
    this.current = control;
  }

  handleSummaryClick(e) {
    e.preventDefault();

    if (this.current === e.currentTarget) {
      this.deactivate(this.current);
    } else {
      if (e.currentTarget.getAttribute('aria-disabled') === null) {
        if (this.current) {
          this.deactivate(this.current);
        }
        this.activate(e.currentTarget);
      }
    }
  }

  handleSummaryKeydown(e) {
    let index = null;

    if (e.key === 'ArrowDown' || e.key === 'Down') {
      e.preventDefault();
      // eslint-disable-next-line max-len
      index = (e.currentTarget === this.controls[this.controls.length - 1]) ? 0 : Array.prototype.indexOf.call(this.controls, e.currentTarget) + 1;
    } else if (e.key === 'ArrowUp' || e.key === 'Up') {
      e.preventDefault();
      // eslint-disable-next-line max-len
      index = (e.currentTarget === this.controls[0]) ? this.controls.length - 1 : Array.prototype.indexOf.call(this.controls, e.currentTarget) - 1;
    } else if (e.key === 'Home') {
      index = 0;
    } else if (e.key === 'End') {
      index = this.controls.length - 1;
    }

    if (index !== null) {
      e.preventDefault();
      this.deactivate(e.currentTarget);
      this.activate(this.controls[index]);
      this.current.focus();
    }
  }

  mount() {
    this.controls.forEach((control) => {
      const id = control.getAttribute('data-accordion-control');
      const target = document.getElementById(id);

      control.setAttribute('id', `control-${id}`);
      control.setAttribute('aria-controls', id);
      control.setAttribute('aria-expanded', 'false');
      target.setAttribute('role', 'region');
      target.setAttribute('aria-labelledby', `control-${id}`);
      target.setAttribute('hidden', '');

      control.addEventListener('click', this.handleSummaryClick);
      control.addEventListener('keydown', this.handleSummaryKeydown);
    });
  }

  unmount() {
    this.element.removeAttribute('role');

    this.controls.forEach((control) => {
      const id = control.getAttribute('data-accordion-control');
      const target = document.getElementById(id);

      control.removeAttribute('id');
      control.removeAttribute('aria-controls');
      control.removeAttribute('aria-expanded');
      control.removeAttribute('aria-disabled');
      target.removeAttribute('role');
      target.removeAttribute('aria-labelledby');
      target.removeAttribute('hidden');

      control.removeEventListener('click', this.handleSummaryClick);
      control.removeEventListener('keydown', this.handleSummaryKeydown);
    });

    this.current = null;
  }
}
