import 'mdn-polyfills/CustomEvent';
import 'mdn-polyfills/NodeList.prototype.forEach';
import Toggler from '../toggler/Toggler';

export default class Filter {
  constructor(element) {
    this.element = element;
    this.control = this.element.querySelector('[data-filter-control]');
    this.target = document.getElementById(this.control.dataset.filterControl);
    this.toggler = new Toggler(this.control, this.target, true);
    this.inputs = this.target.querySelectorAll('[data-filter-input]');
    this.clear = this.element.querySelector('[data-filter-clear]');
    this.count = this.control.dataset.filterControl ? parseInt(this.control.dataset.filterControl) : 0;

    this.handleClear = this.handleClear.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.updateCount();
  }

  handleClear() {
    this.inputs.forEach((input) => {
      input.checked = false;
    });
    this.updateCount();
  }

  updateCount() {
    this.count = 0;

    this.inputs.forEach((input) => {
      if (input.checked) {
        this.count += 1;
      }
    });

    this.control.dataset.filterCount = this.count;
  }

  mount() {
    this.toggler.mount();

    this.inputs.forEach((input) => {
      input.addEventListener('change', this.handleChange);
    });

    if (this.clear) {
      this.clear.addEventListener('click', this.handleClear);
    }
  }

  unmount() {
    this.toggler.unmount();

    this.inputs.forEach((input) => {
      input.removeEventListener('change', this.handleChange);
    });

    if (this.clear) {
      this.clear.removeEventListener('click', this.handleClear);
    }
  }
}
