import 'mdn-polyfills/NodeList.prototype.forEach';
import 'mdn-polyfills/Number.isNaN';
import Swiper, { Pagination, Autoplay } from 'swiper';

export default class Carousel {
  constructor(element) {
    this.element = element;
    this.slides = this.element.querySelectorAll('[data-carousel-slide]');
    this.swiperElement = this.element.querySelector('[data-carousel-swiper]');
    this.pagination = this.element.querySelector('[data-carousel-pagination]');
    this.settings = {
      autoplay: { delay: 4000 },
      pagination: {
        el: this.pagination,
        clickable: true,
        renderBullet: function (index, className) {
          return `<button type="button" class="c-carousel__bullet ${className}"><span class="c-carousel__number">${index < 10 ? "0" : null}${(index + 1)}</span></button>`;
        },
      },
      slidesPerView: 'auto',
    };
    this.swiper;
    this.panels;

    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
  }

  handleMouseEnter() {
    this.swiper.autoplay.stop();
  }

  handleMouseLeave() {
    this.swiper.autoplay.start();
  }

  mount() {
    Swiper.use([Pagination, Autoplay]);
    this.swiper = new Swiper(this.swiperElement, this.settings);
    this.panels = this.element.querySelectorAll('[data-carousel-panel]');

    this.panels.forEach((panel) => {
      panel.addEventListener('mouseenter', this.handleMouseEnter);
      panel.addEventListener('mouseleave', this.handleMouseLeave);
    });
  }

  unmount() {
    this.panels.forEach((panel) => {
      panel.removeEventListener('mouseenter', this.handleMouseEnter);
      panel.removeEventListener('mouseleave', this.handleMouseLeave);
    });

    this.panels = null;

    this.destroy(this.swiper, true);
  }
}
