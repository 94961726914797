import 'mdn-polyfills/NodeList.prototype.forEach';
import Accordion from './Accordion';

document.addEventListener('DOMContentLoaded', () => {
  const accordions = document.querySelectorAll('[data-accordion]');
    if (!accordions.length) {
      return;
    }
    accordions.forEach((element) => {
      const accordion = new Accordion(element);
      accordion.mount();
    });
});
