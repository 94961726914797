import 'mdn-polyfills/NodeList.prototype.forEach';
import Carousel from './Carousel';

document.addEventListener('DOMContentLoaded', () => {
  const carousels = document.querySelectorAll('[data-carousel]');
    if (!carousels.length) {
      return;
    }
    carousels.forEach((element) => {
      const carousel = new Carousel(element);

      if (carousel.slides.length > 1) {
        carousel.mount();
      }
    });
});
