import 'mdn-polyfills/NodeList.prototype.forEach';
import 'mdn-polyfills/Number.isNaN';
import Swiper, { Navigation, Pagination } from 'swiper';

export default class Slider {
  constructor(element) {
    this.element = element;
    this.swiperElement = this.element.querySelector('[data-slider-swiper]');
    this.slides = this.element.querySelectorAll('[data-slider-slide]');
    this.prev = this.element.querySelector('[data-slider-control="prev"]');
    this.next = this.element.querySelector('[data-slider-control="next"]');
    this.pagination = this.element.querySelector('[data-slider-pagination]');
    this.settings = {
      loop: true,
      navigation: { nextEl: this.next, prevEl: this.prev },
      pagination: { el: this.pagination, clickable: true },
      slidesPerView: 'auto',
      on: {
        init: (swiper) => {
          this.updateNavigation(swiper);
        },
        slideChange: (swiper) => {
          this.updateNavigation(swiper);
        }
      }
    };
    this.swiper;
  }

  updateNavigation(swiper) {
    this.prev.removeAttribute('disabled');
    this.next.removeAttribute('disabled');

    if (swiper.isBeginning) {
      this.prev.setAttribute('disabled', '');
    }
    if (swiper.isEnd) {
      this.next.setAttribute('disabled', '');
    }
  }

  mount() {
    Swiper.use([Navigation, Pagination]);

    if (this.element.dataset.slider === "cards") {
      this.settings = {
        ...this.settings,
        loop: false,
        freeMode: true
      }
    }

    this.swiper = new Swiper(this.swiperElement, this.settings);

    this.swiper.on('reachBeginning', () => {
      this.prev.setAttribute('disabled', '');
    });

    this.swiper.on('reachEnd', () => {
      this.next.setAttribute('disabled', '');
    });

  }

  unmount() {
    this.destroy(this.swiper, true);
  }
}
